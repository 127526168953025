<template>
  <div>
    <table id="recibo" v-for="(empresa, index) in partidasEmpresas" :key="index">
      <tr>
        <td colspan="6" class="text-left" style="background-color:antiquewhite;border-color:antiquewhite;border-top-left-radius:5px;padding:0.1em 0.5em;">
          <span style="color:#996f0a;font-size:16px;font-weight:bold;">Contra Recibo</span>
          <div style="color:#996f0a;font-size:8px;font-weight:bold;">{{ empresa.razonsocial }}</div>
          <div style="color:#996f0a;font-size:8px;font-weight:bold;">{{ empresa.domicilio }}</div>
        </td>
        <td colspan="3" class="text-right" style="color:#996f0a;font-size:10.4px;font-weight:bold;border-color:antiquewhite;background-color:antiquewhite;">
          <div class="mb-1">FECHA DE REVISION:</div>
          <div>FECHA DE GENERACION:</div>
        </td>
        <td colspan="1" style="color:#996f0a;font-size:10.4px;border-color:antiquewhite;border-top-right-radius:5px;min-width:90px;">
          <div class="mb-1">/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/</div>
          <div style="font-weight:bold;">{{ fechaCarga || empresa.fecha }}</div>
        </td>
      </tr>
      <tr>
        <td colspan="10" rowspan="1"  style="border-color:antiquewhite;border-bottom-left-radius:5px;border-bottom-right-radius:5px;max-width:300px;">
          <div class="text-left" style="color:#996f0a;font-size:8px;font-weight:bold;border-color:antiquewhite;">RECIBIMOS DE:</div>
          <span style="font-size:12.8px;font-weight:bold;">{{ proveedor }}</span>
          <div style="color:#996f0a;font-size:6.4px;font-weight:bold;border:none;">LOS SIGUIENTES DOCUMENTOS A REVISION:</div>
        </td>
      </tr>
      <tr>
        <td colspan="2" rowspan="1" style="width:132px;color:#996f0a;font-size:7.2px;font-weight:bold;border-width:1.5px 1px 1px 1.5px;border-color:#996f0a;background-color:antiquewhite;border-top-left-radius:5px;">SERIE</td>
        <td colspan="2" rowspan="1" style="width:132px;color:#996f0a;font-size:7.2px;font-weight:bold;border-width:1.5px 1px 1px 1.5px;border-color:#996f0a;background-color:antiquewhite;border-top-left-radius:5px;">FOLIO</td>
        <td colspan="2" rowspan="1" style="width:132px;color:#996f0a;font-size:7.2px;font-weight:bold;border-width:1.5px 1px 1px 1px;border-color:#996f0a;background-color:antiquewhite;">FECHA</td>
        <td colspan="2" rowspan="1" style="width:170px;color:#996f0a;font-size:7.2px;font-weight:bold;border-width:1.5px 1px 1px 1px;border-color:#996f0a;background-color:antiquewhite;">IMPORTE</td>
        <td colspan="2" rowspan="1" style="width:170px;color:#996f0a;font-size:7.2px;font-weight:bold;border-width:1.5px 1.5px 1px 1px;border-color:#996f0a;background-color:antiquewhite;border-top-right-radius:5px;">EMPRESA</td>
      </tr>
      <tr v-for="partida in empresa.partidas" :key="partida.FacturaId">
        <td class="partida" colspan="2" style="font-size:8.8px;border-width:1px 1px 1px 1.5px;border-color:#996f0a;background-color:#fff4e5;">{{ partida.Serie }}</td>
        <td class="partida" colspan="2" style="font-size:8.8px;border-width:1px 1px 1px 1.5px;border-color:#996f0a;background-color:#fff4e5;">{{ partida.Folio }}</td>
        <td class="partida" colspan="2" style="font-size:8.8px;border-width:1px;border-color:#996f0a;background-color:#fff4e5;">{{ partida.Fecha }}</td>
        <td class="partida" colspan="2" style="font-size:8.8px;border-width:1px;border-color:#996f0a;background-color:#fff4e5;">{{ $currencyFormat(partida.Importe || 0) }}</td>
        <td class="partida" colspan="2" style="font-size:8.8px;border-width:1px 1.5px 1px 1px;border-color:#996f0a;background-color:#fff4e5;">{{ partida.Empresa }}</td>
      </tr>
      <tr v-for="index in (30 - empresa.partidas.length)" :key="index">
        <td class="partida" colspan="2" style="border:none"></td>
        <td class="partida" colspan="2" style="border:none"></td>
        <td class="partida" colspan="2" style="border:none"></td>
        <td class="partida" colspan="2" style="border:none"></td>
        <td class="partida" colspan="2" style="border:none"></td>
      </tr>
      <tr>
        <td colspan="10" rowspan="1" class="text-left" style="color:#996f0a;font-size:7.2px;font-weight:bold;border:none;">**La vigencia de este documento es efectiva a partir de la Fecha del Sello de Cuentas por Pagar de {{ empresa.razonsocial }}.</td>
      </tr>
      <tr>
        <td colspan="5" rowspan="1" style="color:#996f0a;font-size:7.2px;font-weight:bold;border-width:1.5px 1px 1px 1.5px;border-color:#996f0a;background-color:antiquewhite;border-top-left-radius:5px;">FIRMA</td>
        <td colspan="5" rowspan="1" style="color:#996f0a;font-size:7.2px;font-weight:bold;border-width:1.5px 1.5px 1px 1px;border-color:#996f0a;background-color:antiquewhite;border-top-right-radius:5px;">SELLO</td>
      </tr>
      <tr>
        <td colspan="5" rowspan="2" style="font-size:16px;font-weight:bold;border-color:antiquewhite;border-bottom-left-radius:5px;"></td>
        <td colspan="5" rowspan="2" style="font-size:16px;font-weight:bold;border-color:antiquewhite;border-bottom-right-radius:5px;"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: [
    'fechaCarga',
    'proveedor',
    'partidasEmpresas',
  ],
};
</script>

<style lang="scss" scoped>
#recibo {
  max-width: 760px;
  min-width: 760px;
}

.partida {
  height: 20px;
  padding: 0.35em;
}

table {
  border: solid 2px #996f0a;
  // border-radius: 5px;
  text-align: center;
  border-collapse: unset;
  overflow: visible !important;
}

thead { display: table-header-group }
tfoot { display: table-row-group }
tr { page-break-inside: avoid }

td {
  border: solid 2px black;
  text-align: center;
  border-collapse: unset;
}

.bold~tr td {
  border: solid 1px lightgray;
}

td {
  padding: 0.5em;
}

[rowspan="2"] {
  height: 6em;
}
</style>
